import React from "react";
import { NumberFormatBase } from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";

const formatHeight = (value) => {
  const stripped = removeNonDigitsFromString(value);
  const size = !!stripped ? stripped.length : 0;

  if (size > 0) {
    let formatted = stripped.slice(0, 1);
    if (size > 1) {
      formatted += "," + stripped.slice(1).padEnd(2, '0').slice(0, 2);
    } else {
      formatted += ",00";
    }
    return formatted;
  }
  return "";
};

const HeightTextField = React.forwardRef(({ onChange, ...other }, ref) => {
  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      format={formatHeight}
    />
  );
});

export default HeightTextField;