import React, { useCallback, useEffect } from "react";
import DadosEnderecoCompradorItauForm from "./DadosEnderecoCompradorItauForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";
import { actions as parceiroActions } from "../../../../../actions/parceiro.actions";

const DadosEnderecoCompradorItauFormConnected = ({
  handleGoTo3,
  events,
  setParticipante,
  participante: comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoResidencia: tiposResidencia },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  console.log("comprador", comprador);
  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo3();
    },
    [handleGoTo3, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (formProps) => {
      const { getValues } = formProps;
      const localEntrega = getValues("tipoEntregaCorrespondencia");

      return {
        tipoEntregaCorrespondencia : validators.string({ required: true }),
        endereco: {
          cep: validators.string({ required: true }),
          logradouro: validators.string({ required: true, maxLength: 40 }),
          numero: validators.string({ required: true, maxLength: 40 }),
          bairro: validators.string({ required: true, maxLength: 40 }),
          municipio: validators.string({ required: true, maxLength: 60 }),
          uf: validators.string({ required: true }),
          tipoResidencia: validators.object({ required: true }),
        },
        enderecoCorrespondencia:
          localEntrega === "OUTRO_ENDERECO"
            ? {
                cep: validators.string({ required: true }),
                logradouro: validators.string({
                  required: true,
                  maxLength: 40,
                }),
                numero: validators.string({ required: true, maxLength: 40 }),
                bairro: validators.string({ required: true, maxLength: 40 }),
                municipio: validators.string({
                  required: true,
                  maxLength: 60,
                }),
                uf: validators.string({ required: true }),
                complemento: validators.string({ maxLength: 40 }),
              }
            : {},
      };
    },
    initialValues: useCallback(
      () => ({
        tipoEntregaCorrespondencia: comprador?.tipoEntregaCorrespondencia === "OU" ? 
          "OUTRO_ENDERECO" : "RESIDENCIA",
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          tipoResidencia: null,
          ...comprador?.endereco,
        },
        enderecoCorrespondencia: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          ...comprador?.enderecoCorrespondencia && {
            cep: comprador.enderecoCorrespondencia.cep,
            logradouro: comprador.enderecoCorrespondencia.logradouro,
            numero: comprador.enderecoCorrespondencia.numero,
            complemento: comprador.enderecoCorrespondencia.complemento,
            bairro: comprador.enderecoCorrespondencia.bairro,
            municipio: comprador.enderecoCorrespondencia.municipio,
            uf: comprador.enderecoCorrespondencia.uf,
          },
        },
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        const enderecoCorrespondencia =
          values.tipoEntregaCorrespondencia  === "OUTRO_ENDERECO"
            ? {
                cep: removeNonDigitsFromString(
                  values.enderecoCorrespondencia?.cep
                ),
                logradouro: values.enderecoCorrespondencia.logradouro,
                numeroLogradouro: values.enderecoCorrespondencia.numero,
                complementoLogradouro:
                  values.enderecoCorrespondencia.complemento,
                bairro: values.enderecoCorrespondencia.bairro,
                municipio: values.enderecoCorrespondencia.municipio,
                uf: values.enderecoCorrespondencia.uf,
              }
            : null;

        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco",
            participante: {
              id: comprador?.id,
              endereco: {
                cep: removeNonDigitsFromString(values.endereco?.cep),
                logradouro: values.endereco.logradouro,
                numeroLogradouro: values.endereco.numero,
                complementoLogradouro: values.endereco.complemento,
                bairro: values.endereco.bairro,
                municipio: values.endereco.municipio,
                uf: values.endereco.uf,
                idTipoResidencia: values.endereco.tipoResidencia?.id,
              },
              tipoEntregaCorrespondencia : values.tipoEntregaCorrespondencia ,
              ...(enderecoCorrespondencia && {
                enderecoCorrespondencia,
              }),
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });
  const { setValue } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const successZipCodeCorrespondenciaCallback = useCallback(
    ({ data }) => {
      setValue("enderecoCorrespondencia.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("enderecoCorrespondencia.bairro", data?.bairro || "", {
        shouldValidate: true,
      });
      setValue("enderecoCorrespondencia.uf", data?.uf || "", {
        shouldValidate: true,
      });
      setValue("enderecoCorrespondencia.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeCorrespondenciaBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCorrespondenciaCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCorrespondenciaCallback]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      tipoEntregaCorrespondencia: comprador?.tipoEntregaCorrespondencia === "OU" ? 
        "OUTRO_ENDERECO" : "RESIDENCIA",
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        tipoResidencia: null,
        ...comprador?.endereco,
      },
      enderecoCorrespondencia: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        ...comprador?.enderecoCorrespondencia && {
          cep: comprador.enderecoCorrespondencia.cep,
          logradouro: comprador.enderecoCorrespondencia.logradouro,
          numero: comprador.enderecoCorrespondencia.numero,
          complemento: comprador.enderecoCorrespondencia.complemento,
          bairro: comprador.enderecoCorrespondencia.bairro,
          municipio: comprador.enderecoCorrespondencia.municipio,
          uf: comprador.enderecoCorrespondencia.uf,
        },
      },
    });
  }, [comprador, reset]);

  return (
    <DadosEnderecoCompradorItauForm
      {...otherProps}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      tiposResidencia={tiposResidencia}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleZipCodeCorrespondenciaBlurred={handleZipCodeCorrespondenciaBlurred}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={events}
    />
  );
};

export default DadosEnderecoCompradorItauFormConnected;
