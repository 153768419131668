import React, { useCallback, useMemo, useState } from "react";
import ContasLiberacaoVendedorItauForm from "./ContasLiberacaoVendedorItauForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isEnviandoComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import { useFieldArray } from "react-hook-form";
import { actions } from "../../../../actions/operacao.actions";
import validators from "../../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";

const ContasLiberacaoVendedorItauFormConnected = ({
  handleGoTo0,
  vendedores,
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoConta: tiposConta, banco: bancos },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const submittingDocumentos = useSelector(isEnviandoComplementoOperacao);

  const contas = useMemo(() => {
    const vendedoresComConjuges = vendedores.reduce((a, v) => {
      a.push(v);
      if (v.conjuge && !vendedores.some(vendedor => vendedor.cpfCnpj === v.conjuge.cpfCnpj)) {
        a.push({ ...v.conjuge, id: v.id });
      }
      return a;
    }, [])

    return vendedoresComConjuges.map((v, i) => {
      if (v.contaLiberacao) {
        return {
          ...v.contaLiberacao,
          index: i,
          nome: v.nome,
          participante: v.id,
          conjuge: !!v.conjugeVendedor
        };
      }

      return {
        index: i,
        nome: v.nome,
        participante: v.id,
        conjuge: !!v.conjugeVendedor
      };
    });
  }, [vendedores]);

  const [habilitados, setHabilitados] = useState(contas.map((c) => !!c.id));

  const handleEnviarDocumentos = useCallback(() => {
    dispatch(
      actions.enviarComplemento.request({
        id: operacao.id,
      })
    );
  }, [dispatch, operacao]);

  const validatePercentualLiberacao = {
    checkTotal: () => {
      const values = formProps.getValues().contas;
      if (!values) return true;
      
      const total = values
        .filter((_, index) => habilitados[values[index].index])
        .reduce((acc, v) => acc + (parseFloat(v.percentualLiberacao) || 0), 0);
      
      return Math.abs(total - 100) < 0.01 || "A soma dos percentuais deve ser igual a 100%";
    },
  };

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      contas: {
        bancoConta: validators.object({ required: true }),
        agenciaConta: validators.string({ required: true }),
        numeroConta: validators.string({ required: true }),
        tipoConta: validators.object({ required: true }),
        percentualLiberacao: validators.number({ required: true, custom: validatePercentualLiberacao }),
      },
    }),
    initialValues: useCallback(
      () => ({
        contas: contas.filter((c) => !!c.id),
      }),
      [contas]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: () => handleGoTo0(),
            instituicao: operacao.codigoInstituicao,
            passo: "contas-liberacao",
            operacao: {
              id: operacao.id,
              participantes: contas.map((v) => {
                if (!habilitados[v.index]) {
                  return {
                    id: v.participante,
                    contaLiberacao: null
                  };
                }

                const accountValues = values.contas.find(conta => conta.index === v.index);
                
                return {
                  id: v.participante,
                  contaLiberacao: accountValues && !!accountValues.numeroConta
                    ? {
                      id: v.id,
                      idBancoConta: accountValues.bancoConta?.id,
                      agenciaConta: accountValues.agenciaConta,
                      numeroConta: accountValues.numeroConta,
                      idTipoConta: accountValues.tipoConta?.id,
                      percentualLiberacao: parseFloat(accountValues.percentualLiberacao),
                      conjuge: v.conjuge,
                    }
                    : null,
                };
              }),
            },
          })
        );
      },
      [dispatch, operacao, habilitados, contas]
    ),
  });

  const contasArray = useFieldArray({
    control: formProps.control,
    name: "contas",
  });

  const handleChangeCheck = useCallback(
    (index) => {
      const newHabilitados = [...habilitados];
      newHabilitados[index] = !newHabilitados[index];
      setHabilitados(newHabilitados);

      if (!newHabilitados[index]) {
        let fieldIndex = null;
        contasArray.fields.forEach((v, x) => {
          if (v.index === index) {
            fieldIndex = x;
          }
        });
        if (fieldIndex !== null) {
          contasArray.remove(fieldIndex);
        }
      } else {
        contasArray.append({
          ...contas[index],
          bancoConta: null,
          agenciaConta: "",
          numeroConta: "",
          tipoConta: null,
          percentualLiberacao: "",
        });
      }

      formProps.clearErrors();
      setTimeout(() => {
        formProps.trigger();
      }, 0);
    },
    [habilitados, contas, contasArray, formProps]
  );

  return (
    <ContasLiberacaoVendedorItauForm
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting || submittingDocumentos}
      contas={contas}
      contasArray={contasArray}
      bancos={bancos}
      tiposConta={tiposConta}
      habilitados={habilitados}
      handleChangeCheck={handleChangeCheck}
      handleGoBack={handleGoTo0}
    />
  );
};

export default ContasLiberacaoVendedorItauFormConnected;
